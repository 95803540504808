import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '#app/components/ui/accordion.tsx'

let faqs = [
	{
		title: 'How long can my book be?',
		content:
			"We've found the AI performs best with books up until 500 pages or 150,000 words. If your book is longer, results might not be as good. It can be more than 500 pages depending on the length of each page.",
	},
	{
		title: 'What happens with my book when I upload it?',
		content:
			"When you submit your manuscript for review, it's used solely for that purpose. We process it through our AI-driven analysis to provide your detailed feedback. After this, your manuscript is not stored, saved, or retained in any form. It’s used once for processing and then immediately erased from our systems.",
	},
	{
		title: 'Is payment secure?',
		content:
			"Yes, we use Lemonsqueezy & Stripe to process payments. Stripe is a secure and trusted payment processor used by millions of businesses worldwide. We don't store any payment information on our servers.",
	},
	{
		title: 'Can I upload books of any genre?',
		content:
			'Yes, we provide tailored feedback for adventure books, focusing on narrative flow, character development, and world-building, among others. For non-adventure books, we assess clarity, structure, and inspiration, ensuring our feedback is relevant to your book’s genre.',
	},
	{
		title: 'Can I upload books in any language?',
		content:
			'Yes, you can upload books in any language. Our AI-driven analysis can process manuscripts in any language, providing you with detailed feedback on your book.',
	},
	{
		title: 'Can I use this for short stories or novellas?',
		content:
			'Yes, our service is suited for a wide range of manuscript lengths, from short stories and novellas to full-length novels. Our feedback is customized to the scope and scale of your work.',
	},
]

export function FAQS() {
	return (
		<section className="w-full" id="faqs">
			<div className="container pb-20 pt-20">
				<h3 className="text-center text-h2">FAQs</h3>
				<div className="h-20" />
				<div className="flex flex-col gap-8">
					{faqs.map(faq => (
						<Faq key={faq.title} title={faq.title} content={faq.content} />
					))}
				</div>
			</div>
		</section>
	)
}

function Faq({
	title,
	content,
}: {
	title: string
	content?: string | JSX.Element
}) {
	return (
		<Accordion type="single" collapsible>
			<AccordionItem value="item-1">
				<AccordionTrigger
					className="text-md md:text-h5"
					iconClassName="w-10 h-10"
				>
					{title}
				</AccordionTrigger>
				<AccordionContent className="text-sm md:text-lg">
					{content}
				</AccordionContent>
			</AccordionItem>
		</Accordion>
	)
}
