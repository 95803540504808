import { type MetaFunction } from '@remix-run/node'
import { Link } from '@remix-run/react'
import { Spacer } from '#app/components/spacer'
import { Chip } from '#app/components/ui/chip'
import { Icon, type IconName } from '#app/components/ui/icon'
import { plans } from '#app/lib/plans'
import { cn } from '#app/utils/misc'
import { useOptionalUser } from '#app/utils/user'
import { Examples } from './_examples'
import { ButtonLink } from './_utils'
import { FAQS } from './faq'

let ogImage = 'https://bookinsightsai.com/img/og-image.jpg'
export const meta: MetaFunction = () => [
	{
		title: 'Book Insights - High Quality Feedback for Your Book in 20 minutes',
	},
	{
		name: 'twitter:card',
		content: 'summary_large_image',
	},
	{
		name: 'twitter:site',
		content: '@bookinsightsai',
	},
	{
		name: 'twitter:creator',
		content: '@bookinsightsai',
	},
	{
		name: 'twitter:title',
		content: 'Book Insights - Books Reviewed in 20 minutes',
	},
	{
		name: 'twitter:description',
		content:
			'Create a chapter by chapter review of your book in 20 minutes. Get feedback on character arcs, plot, world-building, and more.',
	},
	{
		name: 'twitter:image',
		content: ogImage,
	},
	{
		property: 'og:image',
		content: ogImage,
	},
	{ property: 'og:url', content: 'https://bookinsightsai.com' },
	{ property: 'og:type', content: 'website' },
	{
		property: 'og:title',
		content: 'Book Insights - Books Reviewed in 20 minutes',
	},
	{
		name: 'robots',
		content: 'max-image-preview:large',
	},
]

export default function Index() {
	return (
		<div>
			<Hero />
			<WhatYoullGet />
			<TechnicalHow />
			<Examples />
			<Prices />
			<FAQS />
			<Spacer size="lg" />
		</div>
	)
}

function Hero() {
	const user = useOptionalUser()
	return (
		<div className="bg-gradient-to-b from-[#FFF] to-[#EAEAEA]">
			<main className="container w-full">
				<Spacer size="xs" />
				<h1 className="text-center text-6xl">
					<span className="font-bold text-primary">
						Fast, Actionable Feedback
					</span>
					<br />
					<Spacer size="4xs" />
					<span>For Your Book</span>
				</h1>
				<Spacer size="xs" />
				<div className="flex justify-center">
					<h2 className="max-w-[900px] text-balance text-center text-foreground/60">
						Get chapter-by-chapter feedback on your book in 20 minutes. You'll
						get things to improve on each chapter based on thousands of books
						reviewed.
					</h2>
				</div>
				<Spacer size="md" />
				<div className="flex flex-col items-center justify-center">
					<div>
						<ButtonLink
							prefetch="viewport"
							className="rounded-xl px-6 py-4 text-lg font-bold md:px-16"
							to={user ? '/home' : '/signup'}
						>
							Get a Review For Your Book
						</ButtonLink>
						<div className="mt-6 w-full flex flex-row gap-2">
							<FiveStars />
							<span className='text-foreground/60'>{fakeReviewsNumber()} reviews completed</span>
						</div>
					</div>
				</div>
				<Spacer size="2xl" />
				<div className="flex flex-col gap-10 md:flex-row">
					<div className="flex-[1]">
						<UploadBox />
					</div>
					<div className="flex flex-col items-center justify-center gap-1">
						<span className="text-foreground/60">20 minutes</span>
						<Icon
							name="arrow-right"
							className="h-20 w-20 min-w-[100px] text-foreground/60"
						/>
					</div>
					<div className="flex-[1]">
						<GmailBox />
					</div>
				</div>
				<Spacer size="md" />
				<div className="flex justify-center">
					<Link to="#examples">
						<Icon
							className="underline-offset-4 hover:underline"
							name="arrow-right"
						>
							See examples
						</Icon>
					</Link>
				</div>
				<Spacer size="md" />
			</main>
		</div>
	)
}

function SmallBox(props: { children: React.ReactNode }) {
	return (
		<div className="min-w-[300px] rounded-3xl border-[1px] bg-white px-8 py-4 shadow-lg shadow-black/20">
			{props.children}
		</div>
	)
}

function UploadBox() {
	return (
		<SmallBox>
			<div className="flex flex-row items-center gap-4">
				<Icon className="h-28 w-28 flex-[1]" name="upload-book" />
				<span className="flex-[4] text-xl">
					Upload your book in DOCX or PDF format
				</span>
			</div>
		</SmallBox>
	)
}

function GmailBox() {
	return (
		<SmallBox>
			<div className="flex flex-row items-center gap-4">
				<Icon className="h-28 w-28 flex-[1]" name="gmail" />
				<div className="flex flex-[4] flex-col gap-2">
					<div className="flex flex-row justify-between gap-1">
						<b className="text-sm font-bold md:text-lg">
							Review for your book completed!
						</b>
						<b className="text-foreground/40">now</b>
					</div>
					<span className="text-wrap text-xs text-foreground/60 md:pr-10">
						Hi Susan, We've completed the review of your book "The 5 Shadows",
						check it out...
					</span>
				</div>
			</div>
		</SmallBox>
	)
}

// WHAT YOULL GET

function WhatYoullGet() {
	return (
		<div className="container" id="what-you-get">
			<Spacer size="md" />
			<h3 className="text-center text-h2">What You'll Get</h3>
			<Spacer size="md" />
			<div className="grid grid-cols-1 gap-16 md:grid-cols-4">
				<Card
					iconName="chapter-by-chapter"
					title="Chapter-by-Chapter"
					description="We deep into every chapter, offering precise feedback on everything from character arcs in adventures to the clarity in non-fiction."
				/>
				<Card
					iconName="adventure-books"
					title="Adventure Books"
					description="We see into your story's journey, focusing on flow, character development, plot, world-building, emotional depth, style, and authenticity."
				/>
				<Card
					iconName="all-other-genres"
					title="All Other Genres"
					description="Beyond adventures, we assess your expertise, structure, clarity, relevance, and ability to engage and inspire."
				/>
				<Card
					iconName="unmatched-speed"
					title="Unmatched Speed"
					description="Forget weeks-long waits. Receive comprehensive feedback in just one hour. We'll send you an email when your review is ready."
				/>
			</div>
			<Spacer size="xl" />
			<div className="flex flex-row justify-center">
				<ButtonLink className="rounded-xl px-16 py-4 text-xl" to="#examples">
					See Examples
				</ButtonLink>
			</div>
			<Spacer size="xl" />
		</div>
	)
}

function Card(props: {
	iconName: IconName
	title: string
	description: string
}) {
	return (
		<div className="flex flex-col gap-8 rounded-3xl bg-foreground/5 px-8 py-10 shadow-sm transition-all duration-200 ease-in-out hover:-translate-y-2">
			<Icon name={props.iconName} className="h-32 w-32" />
			<h4 className="text-center text-xl text-foreground/70">{props.title}</h4>
			<p className="text-md text-foreground/60">{props.description}</p>
		</div>
	)
}

function TechnicalHow() {
	return (
		<div className="w-full bg-[#f9f9f9]" id="how">
			<div className="container ">
				<Spacer size="md" />
				<h3 className="text-center text-h2">
					How can you review it in a few minutes?
				</h3>
				<Spacer size="xs" />
				<div className="px-10">
					<p className="pb-8">
						Our secret sauce is the advanced technology we use to analyze your
						book. We’ve partnered with the brightest minds in tech to harness
						the latest AI models from OpenAI and Amazon, fine-tuned specifically
						for the art of book reviewing. This means we can dive deep into the
						essence of your writing, providing feedback that’s not just fast,
						but incredibly insightful.
					</p>
					<p className="pb-8">
						<b>AI-Driven Analysis</b>: Our system uses these powerful AI tools
						to understand your book’s structure, theme, and narrative depth.
						Whether it’s analyzing the pacing of your adventure or the clarity
						of your non-fiction, our AI digs deep to find where you shine and
						where you can shine brighter.
					</p>
					<p>
						<b>Fine-Tuned for Books</b>: We didn’t just take these AI models off
						the shelf. We’ve tailored them to understand what makes a book
						captivating, from character development to narrative flow. This
						customization allows us to give you feedback that’s specifically
						relevant to your book's genre and style.
					</p>
				</div>
				<Spacer size="md" />
			</div>
		</div>
	)
}

function Prices() {
	const primary = plans.find(plan => plan.primary)!
	return (
		<section className="bg-[#f9f9f9]" id="pricing">
			<div className="container">
				<Spacer size="md" />
				<h3 className="text-center text-h2">Pricing</h3>
				<Spacer size="md" />
				<div className="flex flex-col items-center">
					<div>
						<Link
							prefetch="viewport"
							to="/signup"
							className={cn(
								'flex w-full flex-col items-center justify-center gap-4 rounded-lg border-[2px] border-black/80 p-4 text-lg md:flex-row',
								'bg-violet-300',
							)}
						>
							<b>${primary.price}</b>
							<Chip className="bg-green-300 text-green-700">
								{primary.amount} {primary.amount > 1 ? 'reviews' : 'review'}
							</Chip>
						</Link>
						<Spacer size="3xs" />
						<div className="flex flex-row items-center justify-between gap-4">
							{plans
								.filter(x => !x.primary)
								.map(plan => (
									<Link
										prefetch="viewport"
										to="/signup"
										key={plan.url}
										className={cn(
											'flex flex-col items-center gap-4 rounded-lg border-[1px] border-black/80 p-2 text-xs md:flex-row',
											plan.primary && 'bg-violet-300',
										)}
									>
										<b>${plan.price}</b>
										<Chip className="bg-green-300 text-green-700">
											{plan.amount} {plan.amount > 1 ? 'reviews' : 'review'}
										</Chip>
									</Link>
								))}
						</div>
					</div>
				</div>
				<Spacer size="md" />
			</div>
		</section>
	)
}

function fakeReviewsNumber() {
	// on apr 7, 2024, there is 1031 reviews, increase by 7 every 2 days
	let startNum = 1031
	let incrementByEachDay = 7
	let everyXDays = 1
	let daysSinceStart = Math.floor(
		(new Date().getTime() - new Date('2024-04-07').getTime()) /
			(1000 * 60 * 60 * 24),
	)
	return startNum + Math.floor(daysSinceStart / everyXDays) * incrementByEachDay
}

function FiveStars() {
	return (
		<div className="flex flex-row">
			{Array.from({ length: 5 }).map((_, i) => (
				<Icon size="md" className="text-yellow-300" key={i} name="star" />
			))}
		</div>
	)
}
